registerProcessor(
  'ADEnvelopeProcessor',
  class ADEnvelopeProcessor extends AudioWorkletProcessor {
    static get parameterDescriptors() {
      return [
        {
          name: 'clock',
          defaultValue: 0,
          minValue: -1,
          maxValue: 1,
          automationRate: 'k-rate',
        },
        {
          name: 'attack',
          defaultValue: 0.001,
          minValue: 0.001,
          maxValue: 60,
          automationRate: 'k-rate',
        },
        {
          name: 'decay',
          defaultValue: 0.5,
          minValue: 0.001,
          maxValue: 60,
          automationRate: 'k-rate',
        },
      ];
    }

    lastClock = 0;
    value = 0;
    rising = false;
    hold: boolean;
    loop: boolean;

    constructor(options: AudioWorkletNodeOptions) {
      super(options);
      this.hold = options.processorOptions.hold;
      this.loop = options.processorOptions.loop;
      this.port.onmessage = ({ data: [type, value] }) => {
        switch (type) {
          case 'hold':
            this.hold = value;
            break;
          case 'loop':
            this.loop = value;
            break;
          default:
            throw new Error('unrecognized message');
        }
      };
    }

    process(
      _: Float32Array[][],
      [[output]]: Float32Array[][],
      {
        clock: [clock],
        attack: [attack],
        decay: [decay],
      }: { [param: string]: Float32Array },
    ): boolean {
      if (this.lastClock <= 0 && clock > 0) {
        this.rising = true;
        this.port.postMessage(['trigger']);
      }
      this.lastClock = clock;

      // cut off processing when envelope is not looping and cycle is complete
      if (!this.rising && !this.loop && this.value === 0) return true;

      let transitionIndex = 0;
      let index = 0;
      let delta;
      while (transitionIndex < output.length) {
        if (this.rising) {
          delta = 1 / attack / sampleRate;
          transitionIndex += Math.ceil((1 - this.value) / delta);
        } else {
          delta = -1 / decay / sampleRate;
          transitionIndex += Math.ceil(this.value / -delta);
        }
        for (
          let end = Math.min(transitionIndex - 1, output.length);
          index < end;
          index++
        ) {
          output[index] = this.value;
          this.value += delta;
        }

        if (transitionIndex < output.length) {
          if (this.rising) {
            output[index] = 1;
            this.value = 1;
            if (this.hold && clock > 0) {
              output.fill(1, transitionIndex);
              break;
            } else {
              this.rising = false;
            }
          } else {
            output[index] = 0;
            this.value = 0;
            if (this.loop) {
              this.rising = true;
              this.port.postMessage(['trigger']);
            } else {
              break;
            }
          }
        }
      }

      return true;
    }
  },
);
